// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_SbjX-{align-items:center;display:flex}.Logo_SbjX-._footer_1qxpm .logoIcon_m6u4J{height:2rem;width:18.2rem}.link_JD-4v{align-items:center;display:flex;padding-top:.2rem;transition:opacity .3s ease}.link_JD-4v._partner_96OKz{padding-bottom:.2rem;padding-top:0}.link_JD-4v._partner_96OKz .logoIcon_m6u4J{color:#3200f0;height:1.5rem;width:8.2rem}@media(max-width:767.98px){.link_JD-4v._partner_96OKz .logoIcon_m6u4J{height:20px;width:74px}}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.link_JD-4v:hover{opacity:.7}}.logoIcon_m6u4J{color:#17181c;height:1.6rem;width:12.6rem}@media(max-width:767.98px){.logoIcon_m6u4J{height:20px;width:130px}}.collaborationIcon_4qjcT{color:#abaebb;margin:0 1rem}@media(max-width:1279.98px){.collaborationIcon_4qjcT{margin:0 1.6rem}}@media(max-width:767.98px){.collaborationIcon_4qjcT{margin:0 8px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"Logo": "Logo_SbjX-",
	"_footer": "_footer_1qxpm",
	"logoIcon": "logoIcon_m6u4J",
	"link": "link_JD-4v",
	"_partner": "_partner_96OKz",
	"collaborationIcon": "collaborationIcon_4qjcT"
};
module.exports = ___CSS_LOADER_EXPORT___;
